/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Section from '../components/Section';
import {
  MV_HEMINGWAY_URL,
  SUBSCRIPTION_HEMINGWAY_APPLE_URL,
  SUBSCRIPTION_HEMINGWAY_GOOGLE_URL,
  SUBSCRIPTION_HEMINGWAY_AMAZON_URL,
  SUBSCRIPTION_HEMINGWAY_SPOTIFY_URL,
  SUBSCRIPTION_HEMINGWAY_LINE_URL,
} from '../Constants';

import DownloadLink from '../components/DownloadLink';
import info from '../../../data/infomation.json';

class Information extends React.Component<typeAppProps> {
  constructor(props) {
    super(props);

    const handleClick = (type: string) => {
      if (!props.ga) {
        return;
      }

      const gaEvent: typeGaEventProps = {
        category: type,
        action:   'click',
        label:    'information',
      };

      props.ga.event(gaEvent);
    };

    this.handleMovieIcon = handleClick.bind(this, 'movie icon');
    this.handleTextLink  = handleClick.bind(this, 'text link');
  }

  handleMovieIcon: Function;

  handleTextLink: Function;

  render() {
    const {
      ga,
      ui,
    } = this.props;

    const mvAlt: string         = info.hemingway_movie_alt[ui.language];

    const appleHemingway: typeSubscriptionIconProps = {
      link:        SUBSCRIPTION_HEMINGWAY_APPLE_URL,
      type:        'apple',
      label:       'information',
      description: 'Apple Music',
      ga,
    };

    const googleHemingway: typeSubscriptionIconProps = {
      link:        SUBSCRIPTION_HEMINGWAY_GOOGLE_URL,
      type:        'google-play',
      label:       'information',
      description: 'Google Play',
      ga,
    };

    const amazonHemingway: typeSubscriptionIconProps = {
      link:        SUBSCRIPTION_HEMINGWAY_AMAZON_URL,
      type:        'amazon',
      label:       'information',
      description: 'Amazon Music',
      ga,
    };

    const spotifyHemingway: typeSubscriptionIconProps = {
      link:        SUBSCRIPTION_HEMINGWAY_SPOTIFY_URL,
      type:        'spotify',
      label:       'information',
      description: 'Spotify',
      ga,
    };

    const lineHemingway: typeSubscriptionIconProps = {
      link:        SUBSCRIPTION_HEMINGWAY_LINE_URL,
      type:        'line',
      label:       'infomation',
      description: 'LINE',
      ga,
    };

    const subscriptions: Array<typeSubscriptionIconProps> = [
      appleHemingway,
      googleHemingway,
      amazonHemingway,
      spotifyHemingway,
      lineHemingway,
    ];

    const mvLink = (target: string) => (
      <a href={MV_HEMINGWAY_URL} target="_blank" rel="noopener noreferrer" onClick={this.handleMovieIcon}>
        <img src={`/images/youtube_${target}.jpg`} alt={mvAlt} />
      </a>
    );

    // ヘミングウェイにも書き尽くせない愛の歌の動画、各サブスクリプションサービスへのリンク
    const HemingwayLink = (
      <div className="infomationCatch">
        <figure className="is-hidden-mobile">
          {mvLink('pc')}
        </figure>
        <figure className="is-hidden-tablet">
          {mvLink('mobile')}
        </figure>
        <DownloadLink array={subscriptions} />
      </div>
    );

    const createLink = (url: string, label: any, lang: string) => {
      if (url.match(/^http/)) {
        return (
          <a href={url} target="_blank" rel="noopener noreferrer" onClick={this.handleTextLink}>
            [
            {label[lang]}
            ]
          </a>
        );
      }

      return (
        <a href={url} onClick={this.handleTextLink}>
          [
          {label[lang]}
          ]
        </a>
      );
    };

    // config/infomation.yml の内容を表示する部分
    const content = (currentLanguageCode: string) => (
      <React.Fragment>
        {HemingwayLink}
        <ul className="infomationNews">
          {
            info.news && info.news.map((c, i) => (
              <li key={`info-new-${i}`}>
                <p>
                  <time dateTime={`${c.date.year}-${c.date.month}-${c.date.day}`}>
                    {`${c.date.year}.${c.date.month}.${c.date.day}`}
                  </time>
                  <span className="univia-pro-font has-text-weight-bold">
                    {
                      c.link && createLink(c.link.url, c.link.label, currentLanguageCode)
                    }
                  </span>
                  {c.description[currentLanguageCode]}
                </p>
              </li>
            ))
          }
        </ul>
      </React.Fragment>
    );

    return (
      <Section title="Information" titleID="information" content={content(ui.language)} navHeight={ui.navHeight} />
    );
  }
}

export default connect(state => ({
  ui: state.ui.data
}))(Information);
