/* flow */

import React from 'react';

const TopPicture = () => (
  <React.Fragment>
    <div style={{ height: '76px' }} className="is-hidden-mobile" />
    <div className="topHeroPhotoPC is-hidden-mobile" />
    <div style={{ height: '52px' }} className="is-hidden-tablet" />
    <div className="topHeroPhotoMobile is-hidden-tablet" />
  </React.Fragment>
);

export default TopPicture;
