/* @flow */
import React from 'react';

const Section = ({ title, titleID, content, navHeight }: typeSectionProps) => (
  <section className="hero sectionStyle" id={titleID}>
    <div style={{ height: navHeight }} ><p /></div>
    <div className="hero-body">
      <div className="container">
        <h1 className="title has-text-centered univia-pro-font" style={{ height: '80px' }}>{title}</h1>
      </div>
      <div className="container">
        {content}
      </div>
    </div>
  </section>
);

export default Section;
