/* @flow */
import React from 'react';
// import Loadable from 'react-loadable';

// import Loading from './Loading';
import Information from '../containers/Information';
import Discography from './Discography';
import Profile from './Profile';
// import Picture from '../containers/Picture';
import TopPicture from './TopPicture';

// const Information = Loadable({
//   loader:  () => import(/* webpackChunkName: "Information" */ '../containers/Information'),
//   loading: Loading,
// });

// const Discography = Loadable({
//   loader:  () => import(/* webpackChunkName: "Discography" */ './Discography'),
//   loading: Loading,
// });

// const Profile = Loadable({
//   loader:  () => import(/* webpackChunkName: "Profile" */ './Profile'),
//   loading: Loading,
// });

// const Picture = Loadable({
//   loader:  () => import(/* webpackChunkName: "Picture" */ '../containers/Picture'),
//   loading: Loading,
// });

const Top = ({ ga, ui }: typeTopProps) => (
  <React.Fragment>
    <TopPicture />
    <Information ga={ga} />
    <Profile ui={ui} />
    <Discography ga={ga} ui={ui} />
  </React.Fragment>
);

export default Top;
