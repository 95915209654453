/* @flow */
import React from 'react';

import Section from './Section';
import {
  MV_HEMINGWAY_URL,
  SUBSCRIPTION_HEMINGWAY_APPLE_URL,
  SUBSCRIPTION_HEMINGWAY_GOOGLE_URL,
  SUBSCRIPTION_HEMINGWAY_AMAZON_URL,
  SUBSCRIPTION_HEMINGWAY_SPOTIFY_URL,
  SUBSCRIPTION_HEMINGWAY_LINE_URL,
} from '../Constants';
import DownloadLink from './DownloadLink';

import discography from '../../../data/discography.json';

export const publishComponent = (hash: any, lang: string, func: any, ga: any) => {
  /*
  yml の構造
---
  -
    title:
      JP: ""
      EN: ""
      CN: ""

    date:
      year: 2018
      month: 7
      day: 2

    link:
      label:
        JP: "MUSIC B.B.- B.B.HEADLINE -"
        EN: "MUSIC B.B.- B.B.HEADLINE -"
        CN: "MUSIC B.B.- B.B.HEADLINE -"
      url: "http://www.music-bb.com/"

    content:
      JP: "注目アーティストとして紹介される。"
      EN: ""
      CN: ""

    subscription_link:
      -
        label: "Apple Music"
        icon: "apple"
        url: "https://itunes.apple.com/jp/album/love-song-that-cannot-be-even-written-down-by-hemingway/1399525770"

    html: "<div />"
  */

  // console.log('publishComponent');
  // console.dir(hash);

  const publishTitle = (lang: string) => {
    if (!hash.title) {
      return null;
    }

    return (
      <h3 className="title is-6">
        {hash.title[lang]}
      </h3>
    );
  };

  const publishDate = () => {
    const d = hash.date;
    if (d) {
      return (
        <time dateTime={`${d.year}-${d.month}-${d.day}`}>
          {`${d.year}.${d.month}.${d.day}`}
        </time>
      );
    }
    return null;
  };

  const publishLink = () => {
    if (!hash.link) {
      return null;
    }

    if (!hash.link.url) {
      return (
        <span className="univia-pro-font has-text-weight-bold">
          { hash.link.label[lang] }
        </span>
      );
    }

    return (
      <span className="univia-pro-font has-text-weight-bold">
        <a href={hash.link.url} target="_blank" rel="noopener noreferrer" onClick={func}>
          { hash.link.label[lang] }
        </a>
      </span>
    );
  };

  const publishSubscriptionLink = (array: any, ga: any) => {
    if (!array) {
      return null;
    }

    const subscriptions = array.map(a => {
      return {
        link:        a.url,
        type:        a.icon,
        label:       'discography',
        description: a.label,
        ga,
      };
    });

    return (
      <DownloadLink array={subscriptions} />
    );
  };

  const publishHTML = (html: string) => {
    if (!html) {
      return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  // main
  if (publishTitle(lang) === null) {
    return (
      <React.Fragment>
        { publishTitle(lang) }
        <ul className="sectionList">
          <li>
            <p>
              { publishDate() }
              { publishLink() }
              { hash.content && hash.content[lang] }
            </p>
            {publishSubscriptionLink(hash.subscription_link)}
            {publishHTML(hash.html)}
          </li>
        </ul>
      </React.Fragment>
    );
  }

  return (
    <ul className="sectionList discographyOtherList">
      <li>
        { publishTitle(lang) }
        <ul className="sectionList">
          <li>
            <p>
              { publishDate() }
              { publishLink() }
              { hash.content && hash.content[lang] }
            </p>
            {publishSubscriptionLink(hash.subscription_link)}
            {publishHTML(hash.html)}
          </li>
        </ul>
      </li>
    </ul>
  );
};


const Discography = ({ ga, ui }: typeTopProps) => {
  // const appleHemingway: typeSubscriptionIconProps = {
  //   link:        SUBSCRIPTION_HEMINGWAY_APPLE_URL,
  //   type:        'apple',
  //   label:       'discography',
  //   description: 'Apple Music',
  //   ga,
  // };

  // const googleHemingway: typeSubscriptionIconProps = {
  //   link:        SUBSCRIPTION_HEMINGWAY_GOOGLE_URL,
  //   type:        'google-play',
  //   label:       'discography',
  //   description: 'Google Play',
  //   ga,
  // };

  // const amazonHemingway: typeSubscriptionIconProps = {
  //   link:        SUBSCRIPTION_HEMINGWAY_AMAZON_URL,
  //   type:        'amazon',
  //   label:       'discography',
  //   description: 'Amazon Music',
  //   ga,
  // };

  // const spotifyHemingway: typeSubscriptionIconProps = {
  //   link:        SUBSCRIPTION_HEMINGWAY_SPOTIFY_URL,
  //   type:        'spotify',
  //   label:       'discography',
  //   description: 'Spotify',
  //   ga,
  // };

  // const lineHemingway: typeSubscriptionIconProps = {
  //   link:        SUBSCRIPTION_HEMINGWAY_LINE_URL,
  //   type:        'line',
  //   label:       'discography',
  //   description: 'LINE',
  //   ga,
  // };

  // const subscriptions: Array<typeSubscriptionIconProps> = [
  //   appleHemingway,
  //   googleHemingway,
  //   amazonHemingway,
  //   spotifyHemingway,
  //   lineHemingway,
  //   ga,
  // ];

  const handleClick = () => {
    if (!ga) {
      return;
    }

    const gaEvent: typeGaEventProps = {
      category: 'text link',
      action:   'click',
      label:    'discography'
    };

    ga.event(gaEvent);
  };

  const content = (
    <React.Fragment>

      <h2 className="title is-4 univia-pro-font discographyTitle">
        TV
      </h2>
      { discography.tv.map(c => publishComponent(c, ui.language, handleClick)) }

      <h2 className="title is-4 univia-pro-font discographyTitle">
        CD
      </h2>
      { discography.cd.map(c => publishComponent(c, ui.language, handleClick)) }

      <h2 className="title is-4 univia-pro-font discographyTitle">
        Music Video
      </h2>
      { discography.music_video.map(c => publishComponent(c, ui.language, handleClick)) }

      <h2 className="title is-4 univia-pro-font discographyTitle">
        Radio
      </h2>
      { discography.radio.map(c => publishComponent(c, ui.language, handleClick)) }

      <h2 className="title is-4 univia-pro-font discographyTitle">
        Others
      </h2>
      { discography.others.map(c => publishComponent(c, ui.language, handleClick)) }

    </React.Fragment>
  );

  return (
    <Section title="Discography" titleID="discography" content={content} navHeight={ui.navHeight} />
  );
};

export default Discography;
