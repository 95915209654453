/* @flow */
import React from 'react';

import Section from './Section';
import profile from '../../../data/profile.json';

const Profile = ({ ui }: typeProfileProps) => {
  const basic = (
    <div className="profileBasic">
      <p>
        <span className="profileBasicLabel">
          {profile.birth_place.label[ui.language]}:
        </span>
        <span className="profileBasicContent">
          {profile.birth_place.content[ui.language]}
        </span>
      </p>
      <p>
        <span className="profileBasicLabel">
          {profile.birth_date.label[ui.language]}:
        </span>
        <span className="profileBasicContent">
          {profile.birth_date.content[ui.language]}
        </span>
      </p>
      <p>
        <span className="profileBasicLabel">
          {profile.sex.label[ui.language]}:
        </span>
        <span className="profileBasicContent">
          {profile.sex.content[ui.language]}
        </span>
      </p>
      <p>
        <span className="profileBasicLabel">
          {profile.blood_type.label[ui.language]}:
        </span>
        <span className="profileBasicContent">
          {profile.blood_type.content[ui.language]}
        </span>
      </p>
    </div>
  );

  const content = (
    <div className="profile">
      {basic}
      {
        profile.description.map((c, i) => (
          <p className="profileDescription" key={`profile_desc_${i}`}>
            {c[ui.language]}
          </p>
        ))
      }
    </div>
  );

  return (
    <Section title="Profile" titleID="profile" content={content} navHeight={ui.navHeight} />
  );
};


export default Profile;
