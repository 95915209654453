/* @flow */
import React from 'react';

const handleClick = (ga: any, label: string) => {
  if (!ga) {
    return;
  }

  const gaEvent: typeGaEventProps = {
    category: 'subscription_icon',
    action:   'click',
    label,
  };

  ga.event(gaEvent);
};

// const makeLabel = (str: string) => str.toLowerCase().replace(' ', '-');

const SubscriptionIcon = ({
  type,
  link,
  description,
  cssClass,
  ga
}: typeSubscriptionIconProps) => (
  <span className={`univia-pro-font ${cssClass || ''}`}>
    <a href={link} target="_blank" rel="noopener noreferrer" onClick={handleClick.bind(this, ga, type)}>
      <i className={`fab fa-lg fa-${type} faIcon`} />
      {description}
    </a>
  </span>
);

export default SubscriptionIcon;
